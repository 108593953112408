<template>
  <div class="card shadow-sm mb-4">
    <div class="ps-5 pe-5 p-5">
      <div class="row align-items-baseline">
        <div class="mb-4 col-md-4">
          <label class="fw-bold text-gray-600" label-for="descrizione"
            >Identificativo campo*</label
          >
          <Field
            :name="`campi[${index}].id_campo`"
            type="text"
            :value="campo.id_campo"
            class="form-control"
            disabled
          />
          <Field
            :name="`campi[${index}].id_omologazione_impianti_campi`"
            type="hidden"
            :value="campo.id_omologazione_impianti_campi"
            class="form-control"
            disabled
          />
          <ErrorMessage
            class="help-message"
            style="color: red"
            :name="`campi[${index}].id_campo`"
          />
        </div>
        <div class="mb-4 col-md-4">
          <label class="fw-bold text-gray-600" label-for="descrizione"
            >Descrizione*</label
          >
          <Field
            :name="`campi[${index}].nome_campo`"
            type="text"
            v-model="campo.nome_campo"
            class="form-control"
            disabled
          />
          <ErrorMessage
            class="help-message"
            style="color: red"
            :name="`campi[${index}].nome_campo`"
          />
        </div>
        <div class="mb-4 col-md-4">
          <label class="fw-bold text-gray-600" label-for="disciplina"
            >Disciplina*</label
          >
          <Field
            :name="`campi[${index}].disciplina`"
            type="text"
            :value="campo.disciplina"
            class="form-control"
            disabled
          />
          <ErrorMessage
            class="help-message"
            style="color: red"
            :name="`campi[${index}].disciplina`"
          />
        </div>
      </div>
      <!-- OK -->
      <div class="row align-items-baseline">
        <div class="mb-4 col-md-2">
          <label class="fw-bold text-gray-600" label-for="disciplina"
            >Lunghezza (m)*</label
          >
          <Field
            type="number"
            min="0"
            class="form-control"
            :name="`campi[${index}].lunghezza`"
            v-model="campo.lunghezza"
          />
          <ErrorMessage
            class="help-message"
            style="color: red"
            :name="`campi[${index}].lunghezza`"
          />
        </div>
        <div class="mb-4 col-md-2">
          <label class="fw-bold text-gray-600" label-for="disciplina"
            >Larghezza (m)*</label
          ><Field
            type="number"
            min="0"
            class="form-control"
            :name="`campi[${index}].larghezza`"
            v-model="campo.larghezza"
          />
          <ErrorMessage
            class="help-message"
            style="color: red"
            :name="`campi[${index}].larghezza`"
          />
        </div>
        <div class="mb-4 col-md-2">
          <label class="fw-bold text-gray-600" label-for="disciplina"
            >{{
              campo.id_disciplina == 4332
                ? "Out Laterali 1 (m)*"
                : "Out laterale 1 per gioco esterno *"
            }}
            <Popper
              v-show="campo.id_disciplina != 4332"
              arrow
              content="distaza 1 misurata
                            dal centro rete e parallela al lato corto (m)*"
              placement="top-end"
            >
              <i class="bi bi-info-circle-fill text-center"></i> </Popper
          ></label>

          <Field
            type="number"
            min="0"
            class="form-control"
            :name="`campi[${index}].out_laterali_sinistro`"
            v-model="campo.out_laterali_sinistro"
          />
          <ErrorMessage
            class="help-message"
            style="color: red"
            :name="`campi[${index}].out_laterali_sinistro`"
          />
        </div>
        <div class="mb-4 col-md-2">
          <label class="fw-bold text-gray-600" label-for="disciplina"
            >{{
              campo.id_disciplina == 4332
                ? "Out Laterali 2 (m)*"
                : "Out laterale 2 per gioco esterno *"
            }}
            <Popper
              v-show="campo.id_disciplina != 4332"
              arrow
              content="distaza 2
                            misurata dal centro rete e parallela al lato corto
                            (m)*"
              placement="top-end"
            >
              <i class="bi bi-info-circle-fill text-center"></i> </Popper
          ></label>
          <Field
            type="number"
            min="0"
            class="form-control"
            :name="`campi[${index}].out_laterali_destro`"
            v-model="campo.out_laterali_destro"
          />
          <ErrorMessage
            class="help-message"
            style="color: red"
            :name="`campi[${index}].out_laterali_destro`"
          />
        </div>
        <div class="mb-4 col-md-2">
          <label class="fw-bold text-gray-600" label-for="disciplina"
            >{{
              campo.id_disciplina == 4332
                ? "Out Fondo 1 (m)*"
                : "Out 1 per gioco esterno *"
            }}
            <Popper
              v-show="campo.id_disciplina != 4332"
              arrow
              content="distanza 1 parallelo al
                            lato lungo, distanza dalla rete (m)*"
              placement="top-end"
            >
              <i class="bi bi-info-circle-fill text-center"></i> </Popper
          ></label>

          <Field
            type="number"
            min="0"
            class="form-control"
            :name="`campi[${index}].out_fondo_sinistro`"
            v-model="campo.out_fondo_sinistro"
          />
          <ErrorMessage
            class="help-message"
            style="color: red"
            :name="`campi[${index}].out_fondo_sinistro`"
          />
        </div>
        <div class="mb-4 col-md-2">
          <label class="fw-bold text-gray-600" label-for="disciplina"
            >{{
              campo.id_disciplina == 4332
                ? "Out Fondo 2 (m)*"
                : "Out 2 per gioco esterno *"
            }}
            <Popper
              v-show="campo.id_disciplina != 4332"
              arrow
              content="distanza 2 parallelo al
                            lato lungo, distanza dalla rete (m)*"
              placement="top-end"
            >
              <i class="bi bi-info-circle-fill text-center"></i> </Popper
          ></label>
          <Field
            type="number"
            min="0"
            class="form-control"
            :name="`campi[${index}].out_fondo_destro`"
            v-model="campo.out_fondo_destro"
          />
          <ErrorMessage
            class="help-message"
            style="color: red"
            :name="`campi[${index}].out_fondo_destro`"
          />
        </div>
      </div>
      <!-- OK -->
      <div class="row align-items-baseline">
        <div class="mb-4 col-md-3">
          <label class="fw-bold text-gray-600" label-for="disciplina"
            >Distanza linea di battuta dalla rete 1 (m)
            {{
              campo.id_disciplina == 172 || campo.id_disciplina == 4332
                ? "*"
                : ""
            }}</label
          >
          <Field
            type="number"
            min="0"
            class="form-control"
            :name="`campi[${index}].distanza_linea_battuta_1`"
            v-model="campo.distanza_linea_battuta_1"
            :disabled="campo.id_disciplina == 171 && campo.id_disciplina == 173"
          />
          <ErrorMessage
            class="help-message"
            style="color: red"
            :name="`campi[${index}].distanza_linea_battuta_1`"
          />
        </div>
        <div class="mb-4 col-md-3">
          <label class="fw-bold text-gray-600" label-for="disciplina"
            >Distanza linea di battuta dalla rete 2 (m){{
              campo.id_disciplina == 172 || campo.id_disciplina == 4332
                ? "*"
                : ""
            }}</label
          ><Field
            type="number"
            min="0"
            class="form-control"
            :name="`campi[${index}].distanza_linea_battuta_2`"
            v-model="campo.distanza_linea_battuta_2"
          />
          <ErrorMessage
            class="help-message"
            style="color: red"
            :name="`campi[${index}].distanza_linea_battuta_2`"
          />
        </div>
        <div class="mb-4 col-md-3">
          <label class="fw-bold text-gray-600" label-for="disciplina"
            >Altezza libera minima su tutta la superficie di gioco *

            <Popper arrow content="superiore a 9m" placement="top-end">
              <i class="bi bi-info-circle-fill text-center"></i> </Popper
          ></label>
          <SelectInput2
            :options="optionsTrueFalse"
            :name="`campi[${index}].altezza_libera_minima_tennis`"
            placeholder="-"
            :value="campo.altezza_libera_minima_tennis"
            :disabled="campo.id_disciplina == 172"
          />
        </div>
        <div class="mb-4 col-md-3">
          <label class="fw-bold text-gray-600" label-for="disciplina"
            >Altezza libera minima su tutta la superficie di gioco *
            <Popper arrow content="superiore a 6m" placement="top-end">
              <i class="bi bi-info-circle-fill text-center"></i>
            </Popper>
          </label>
          <SelectInput
            :options="optionsTrueFalse"
            :name="`campi[${index}].altezza_libera_minima_padel`"
            placeholder="-"
            :value="campo.altezza_libera_minima_padel"
            :disabled="campo.id_disciplina != 172"
          />
        </div>
      </div>
      <div class="row align-items-baseline" v-if="campo.id_disciplina == 172">
        <div class="mb-4 col-md-3">
          <label class="fw-bold text-gray-600" label-for="disciplina"
            >Distanza laterale della riga centrale di battuta 1 (m)*</label
          >
          <Field
            type="number"
            min="0"
            class="form-control"
            :name="`campi[${index}].distanza_laterale_battuta_1`"
            v-model="campo.distanza_laterale_battuta_1"
            :disabled="campo.id_disciplina != 172"
          />
          <ErrorMessage
            class="help-message"
            style="color: red"
            :name="`campi[${index}].distanza_laterale_battuta_1`"
          />
        </div>
        <div class="mb-4 col-md-3">
          <label class="fw-bold text-gray-600" label-for="disciplina"
            >Distanza laterale della riga centrale di battuta 2 (m)*</label
          ><Field
            type="number"
            min="0"
            class="form-control"
            :name="`campi[${index}].distanza_laterale_battuta_2`"
            v-model="campo.distanza_laterale_battuta_2"
            :disabled="campo.id_disciplina != 172"
          />
          <ErrorMessage
            class="help-message"
            style="color: red"
            :name="`campi[${index}].distanza_laterale_battuta_2`"
          />
        </div>
        <div class="mb-4 col-md-3">
          <label class="fw-bold text-gray-600" label-for="disciplina"
            >Tipologia vetro campo
            {{ campo.id_disciplina == 172 ? "*" : "" }}:</label
          >
          <SelectInput
            :options="omologazioni_tipologia_vetro_campi"
            :name="`campi[${index}].id_tipologia_vetro`"
            placeholder="-"
            :value="campo.id_tipologia_vetro"
            :disabled="campo.id_disciplina != 172"
          />
        </div>
        <div class="mb-4 col-md-3">
          <label class="fw-bold text-gray-600" label-for="disciplina"
            >Spessore vetro (mm)
            {{ campo.id_disciplina == 172 ? "*" : "" }}</label
          >
          <Field
            type="number"
            min="0"
            class="form-control"
            :name="`campi[${index}].spessore_vetro`"
            v-model="campo.spessore_vetro"
            :disabled="campo.id_disciplina != 172"
          />
          <ErrorMessage
            class="help-message"
            style="color: red"
            :name="`campi[${index}].spessore_vetro`"
          />
        </div>
      </div>
      <div class="row align-items-baseline pt-2 mb-4">
        <div class="col-md-4">
          <label class="fw-bold text-gray-600" label-for="descrizione"
            >Numero posti a sedere giocatori *</label
          >
          <Field
            type="number"
            min="0"
            class="form-control"
            :name="`campi[${index}].numero_posti`"
            v-model="campo.numero_posti"
          />
          <ErrorMessage
            class="help-message"
            style="color: red"
            :name="`campi[${index}].numero_posti`"
          />
        </div>
        <div class="col-md-8">
          <label class="fw-bold text-gray-600" label-for="disciplina"
            >Tipologia campo aperto/coperto*</label
          >
          <SelectInput
            :options="omologazioni_tipo_impianti_campo"
            placeholder="-"
            :name="`campi[${index}].id_tipo_campo_omologazione`"
            :value="campo.id_tipo_campo_omologazione"
            @changeSelect="changeTipologiaCoperturaCampo(index, $event)"
          />
        </div>
      </div>
      <div v-if="campo.id_tipo_campo_omologazione == 2">
        <div class="row align-items-baseline">
          <div class="mb-4 col-md-4">
            <label class="fw-bold text-gray-600" label-for="descrizione"
              >Copertura*</label
            >
            <SelectInput
              :options="omologazioni_tipo_copertura_campi"
              :name="`campi[${index}].tipo_copertura`"
              placeholder="-"
              :value="campo.tipo_copertura"
              :disabled="campo.id_tipo_campo_omologazione != 2"
            />
          </div>
          <div class="mb-4 col-md-5">
            <label class="fw-bold text-gray-600" label-for="descrizione"
              >Tipologia*</label
            >
            <SelectInput
              :options="omologazioni_campi_coperti"
              :name="`campi[${index}].tipologie_campi_coperti`"
              placeholder="-"
              :value="campo.tipologie_campi_coperti"
              @changeSelect="
                changeTipologiaCoperturaCampoCoperto(index, $event)
              "
              :disabled="campo.id_tipo_campo_omologazione != 2"
            />
          </div>
          <div class="mb-4 col-md-3">
            <label class="fw-bold text-gray-600" label-for="descrizione"
              >Presenza copertura*
              <Popper
                arrow
                content="con aperture scorrevoli
                              laterali"
                placement="top-end"
              >
                <i class="bi bi-info-circle-fill text-center"></i> </Popper
            ></label>
            <!--chiamato aperture_scorrevoli_laterali perchè in prod il campo ha questo nome-->
            <SelectInput
              :options="optionsTrueFalse"
              :name="`campi[${index}].aperture_scorrevoli_laterali`"
              placeholder="-"
              :value="campo.aperture_scorrevoli_laterali"
              :disabled="campo.id_tipo_campo_omologazione < 2"
            />
          </div>
        </div>
        <div class="row align-items-baseline">
          <div
            :class="{
              'mb-4 col-md-4': campo.id_disciplina === 4332,
              'mb-4 col-md-4': campo.id_disciplina === 172 || 171,
            }"
          >
            <label class="fw-bold text-gray-600" label-for="descrizione"
              >Impianto climatizzazione campi*</label
            >
            <SelectInput
              :options="optionsTrueFalse"
              :name="`campi[${index}].presenza_climatizzazione`"
              placeholder="-"
              :value="campo.presenza_climatizzazione"
              @changeSelect="setClimatizzazione(index, $event)"
              :disabled="campo.id_tipo_campo_omologazione != 2"
            />
          </div>
          <div v-if="campo.id_disciplina == 4332" class="mb-4 col-md-4">
            <label class="fw-bold text-gray-600" label-for="disciplina"
              >Altezza sopra la linea laterale 1 in corrispondenza della
              rete(m)*</label
            >

            <Field
              type="number"
              min="0"
              class="form-control"
              :name="`campi[${index}].altezza_laterale_1`"
              v-model="campo.altezza_laterale_1"
              :disabled="campo.id_tipo_campo_omologazione != 2"
            />
            <ErrorMessage
              class="help-message"
              style="color: red"
              :name="`campi[${index}].altezza_laterale_1`"
            />
          </div>
          <div v-if="campo.id_disciplina == 4332" class="mb-4 col-md-4">
            <label class="fw-bold text-gray-600" label-for="disciplina"
              >Altezza sopra la linea laterale 2 in corrispondenza della
              rete(m)*</label
            ><Field
              type="number"
              min="0"
              class="form-control"
              :name="`campi[${index}].altezza_laterale_2`"
              v-model="campo.altezza_laterale_2"
              :disabled="campo.id_tipo_campo_omologazione != 2"
            />
            <ErrorMessage
              class="help-message"
              style="color: red"
              :name="`campi[${index}].altezza_laterale_2`"
            />
          </div>
          <div
            v-if="campo.id_disciplina == 172"
            :class="{
              'mb-4 col-md-4':
                campo.id_disciplina == 172 || campo.id_disciplina == 171,
            }"
          >
            <label class="fw-bold" label-for="disciplina"
              >Altezza Massima (m)</label
            ><Field
              type="number"
              min="0"
              class="form-control"
              :name="`campi[${index}].altezza_massima`"
              v-model="campo.altezza_massima"
              :disabled="campo.id_tipo_campo_omologazione != 2"
            />
            <ErrorMessage
              class="help-message"
              style="color: red"
              :name="`campi[${index}].altezza_massima`"
            />
          </div>
          <div class="mb-4 col-md-4" v-if="campo.id_disciplina == 172">
            <label class="fw-bold text-gray-600" label-for="disciplina"
              >Altezza sul centro della rete{{
                campo.id_tipo_campo_omologazione == 2 ? "*" : ""
              }}</label
            >
            <Field
              type="number"
              min="0"
              class="form-control"
              :name="`campi[${index}].altezza_centro_rete`"
              v-model="campo.altezza_centro_rete"
              :disabled="campo.id_tipo_campo_omologazione != 2"
            />
            <ErrorMessage
              class="help-message"
              style="color: red"
              :name="`campi[${index}].altezza_centro_rete`"
            />
          </div>
        </div>
        <!--aggiunta row solo per campi dismessi ma visibili quando omologazione stato 2 (statoprops è lo stato dell'omologazione) (in attesa)-->
        <div
          class="row bg-secondary p-2"
          v-if="campo.id_disciplina == 4332 && statoprops == 2"
        >
          <div
            v-if="campo.id_disciplina == 4332 && campo.altezza_massima != null"
            :class="{
              'mb-4 col-md-3 text-gray-600': campo.id_disciplina == 4332,
            }"
          >
            <label class="fw-bold" label-for="disciplina"
              >Altezza Massima (m)</label
            ><Field
              type="number"
              min="0"
              class="form-control"
              :name="`campi[${index}].altezza_massima`"
              v-model="campo.altezza_massima"
              :disabled="
                campo.id_disciplina == 4332 ||
                campo.id_tipo_campo_omologazione != 2
              "
            />
            <ErrorMessage
              class="help-message"
              style="color: red"
              :name="`campi[${index}].altezza_massima`"
            />
          </div>

          <div
            v-if="
              campo.id_disciplina == 4332 &&
              campo.altezza_linee_laterali != null
            "
            class="mb-4 col-md-3 text-gray-600"
          >
            <label class="fw-bold" label-for="disciplina"
              >Altezza sulle linee laterali{{
            }}</label>
            <Field
              type="number"
              min="0"
              class="form-control"
              :name="`campi[${index}].altezza_linee_laterali`"
              v-model="campo.altezza_linee_laterali"
              :disabled="
                campo.id_tipo_campo_omologazione != 2 ||
                campo.id_disciplina == 4332
              "
            />
            <ErrorMessage
              class="help-message"
              style="color: red"
              :name="`campi[${index}].altezza_linee_laterali`"
            />
          </div>
          <div
            v-if="campo.id_disciplina == 4332 && campo.altezza_fondo_1 != null"
            class="mb-4 col-md-3"
          >
            <label class="fw-bold text-gray-600" label-for="disciplina"
              >Altezza fondo 1 (m){{
            }}</label>
            <Field
              type="number"
              min="0"
              class="form-control"
              :name="`campi[${index}].altezza_fondo_1`"
              v-model="campo.altezza_fondo_1"
              :disabled="
                campo.id_tipo_campo_omologazione != 2 ||
                campo.id_disciplina == 4332
              "
            />
            <ErrorMessage
              class="help-message"
              style="color: red"
              :name="`campi[${index}].altezza_fondo_1`"
            />
          </div>
          <div
            v-if="campo.id_disciplina == 4332 && campo.altezza_fondo_2 != null"
            class="mb-4 col-md-3"
          >
            <label class="fw-bold text-gray-600" label-for="disciplina"
              >Altezza fondo 2 (m){{
            }}</label>
            <Field
              type="number"
              min="0"
              class="form-control"
              :name="`campi[${index}].altezza_fondo_2`"
              v-model="campo.altezza_fondo_2"
              :disabled="
                campo.id_tipo_campo_omologazione != 2 ||
                campo.id_disciplina == 4332
              "
            />
            <ErrorMessage
              class="help-message"
              style="color: red"
              :name="`campi[${index}].altezza_fondo_2`"
            />
          </div>
        </div>
        <div class="row align-items-baseline">
          <div class="mb-4 col-md-4" v-if="campo.id_disciplina == 4332">
            <label class="fw-bold text-gray-600" label-for="disciplina"
              >Altezza sul centro della rete{{
                campo.id_tipo_campo_omologazione
              }}</label
            >
            <Field
              type="number"
              min="0"
              class="form-control"
              :name="`campi[${index}].altezza_centro_rete`"
              v-model="campo.altezza_centro_rete"
              :disabled="campo.id_tipo_campo_omologazione != 2"
            />
            <ErrorMessage
              class="help-message"
              style="color: red"
              :name="`campi[${index}].altezza_centro_rete`"
            />
          </div>

          <div v-if="campo.id_disciplina == 4332" class="mb-4 col-md-4">
            <label class="fw-bold text-gray-600" label-for="disciplina"
              >Altezza a 4,50 mt dalla linea di fondo 1{{
                campo.id_tipo_campo_omologazione == 2 ? "*" : ""
              }}</label
            ><Field
              type="number"
              min="0"
              class="form-control"
              :name="`campi[${index}].altezza_linea_fondo`"
              v-model="campo.altezza_linea_fondo"
              :disabled="campo.id_tipo_campo_omologazione != 2"
            />
            <ErrorMessage
              class="help-message"
              style="color: red"
              :name="`campi[${index}].altezza_linea_fondo`"
            />
          </div>

          <div v-if="campo.id_disciplina == 4332" class="mb-4 col-md-4">
            <label class="fw-bold text-gray-600" label-for="disciplina"
              >Altezza a 4,50 mt dalla linea di fondo 2{{
                campo.id_tipo_campo_omologazione == 2 ? "*" : ""
              }}</label
            ><Field
              type="number"
              min="0"
              class="form-control"
              :name="`campi[${index}].altezza_linea_fondo_2`"
              v-model="campo.altezza_linea_fondo_2"
              :disabled="campo.id_tipo_campo_omologazione != 2"
            />
            <ErrorMessage
              class="help-message"
              style="color: red"
              :name="`campi[${index}].altezza_linea_fondo_2`"
            />
          </div>
          <div class="mb-4 col-md-3">
            <label class="fw-bold text-gray-600" label-for="disciplina"
              >Altezza sopra angolo 1 del campo di gioco (m){{
                campo.id_tipo_campo_omologazione == 2 ? "*" : ""
              }}</label
            ><Field
              type="number"
              min="0"
              class="form-control"
              :name="`campi[${index}].altezza_angoli`"
              v-model="campo.altezza_angoli"
              :disabled="campo.id_tipo_campo_omologazione != 2"
            />
            <ErrorMessage
              class="help-message"
              style="color: red"
              :name="`campi[${index}].altezza_angoli`"
            />
          </div>
          <div class="mb-4 col-md-3">
            <label class="fw-bold text-gray-600" label-for="disciplina"
              >Altezza sopra angolo del campo di gioco (m){{
                campo.id_tipo_campo_omologazione == 2 ? "*" : ""
              }}</label
            ><Field
              type="number"
              min="0"
              class="form-control"
              :name="`campi[${index}].altezza_angoli_2`"
              v-model="campo.altezza_angoli_2"
              :disabled="campo.id_tipo_campo_omologazione != 2"
            />
            <ErrorMessage
              class="help-message"
              style="color: red"
              :name="`campi[${index}].altezza_angoli_2`"
            />
          </div>
          <div class="mb-4 col-md-3">
            <label class="fw-bold text-gray-600" label-for="disciplina"
              >Altezza sopra angolo 3 del campo di gioco (m){{
                campo.id_tipo_campo_omologazione == 2 ? "*" : ""
              }}</label
            ><Field
              type="number"
              min="0"
              class="form-control"
              :name="`campi[${index}].altezza_angoli_3`"
              v-model="campo.altezza_angoli_3"
              :disabled="campo.id_tipo_campo_omologazione != 2"
            />
            <ErrorMessage
              class="help-message"
              style="color: red"
              :name="`campi[${index}].altezza_angoli_3`"
            />
          </div>
          <div class="mb-4 col-md-3">
            <label class="fw-bold text-gray-600" label-for="disciplina"
              >Altezza sopra angolo 4 del campo di gioco (m){{
                campo.id_tipo_campo_omologazione == 2 ? "*" : ""
              }}</label
            ><Field
              type="number"
              min="0"
              class="form-control"
              :name="`campi[${index}].altezza_angoli_4`"
              v-model="campo.altezza_angoli_4"
              :disabled="campo.id_tipo_campo_omologazione != 2"
            />
            <ErrorMessage
              class="help-message"
              style="color: red"
              :name="`campi[${index}].altezza_angoli_4`"
            />
          </div>
        </div>
        <div class="row align-items-baseline">
          <div class="mb-4 col-md-2">
            <label class="fw-bold text-gray-600" label-for="descrizione"
              >Periodo copertura{{
                campo.id_tipo_campo_omologazione == 2 ? "*" : ""
              }}</label
            >
            <SelectInput
              :options="omologazioni_tipologia_copertura_impianti"
              :name="`campi[${index}].tipologia_copertura`"
              placeholder="-"
              :value="campo.tipologia_copertura"
              @changeSelect="changePeriodoCoperturaCampo(index, $event)"
              :disabled="campo.id_tipo_campo_omologazione != 2"
            />
          </div>
          <div class="mb-4 col-md-2">
            <label class="fw-bold text-gray-600" label-for="descrizione"
              >Data inizio copertura{{
                campo.tipologia_copertura == 1 ? "*" : ""
              }}</label
            >
            <Datepicker
              v-model="campo.data_inizio_copertura"
              @update:modelValue="
                $event
                  ? (campo.data_inizio_copertura = fromUTCtoISODate($event))
                  : ''
              "
              placeholder=""
              :format="format"
              :monthChangeOnScroll="false"
              :enableTimePicker="false"
              inputClassName="h-28"
              autoApply
              :disabled="campo.tipologia_copertura != 1"
            />
            <ErrorMessage
              class="help-message"
              style="color: red"
              :name="`campi[${index}].data_inizio_copertura`"
            />
            <Field
              :name="`campi[${index}].data_inizio_copertura`"
              type="hidden"
              class="form-control"
              v-model="campo.data_inizio_copertura"
            />
          </div>
          <div class="mb-4 col-md-2">
            <label class="fw-bold text-gray-600" label-for="descrizione"
              >Data fine copertura{{
                campo.tipologia_copertura == 1 ? "*" : ""
              }}</label
            >
            <Datepicker
              v-model="campo.data_fine_copertura"
              @update:modelValue="
                $event
                  ? (campo.data_fine_copertura = fromUTCtoISODate($event))
                  : ''
              "
              placeholder=""
              :format="format"
              :monthChangeOnScroll="false"
              :enableTimePicker="false"
              inputClassName="h-28"
              autoApply
              :disabled="campo.tipologia_copertura != 1"
            />
            <ErrorMessage
              class="help-message"
              style="color: red"
              :name="`campi[${index}].data_fine_copertura`"
            />
            <Field
              :name="`campi[${index}].data_fine_copertura`"
              type="hidden"
              class="form-control"
              v-model="campo.data_fine_copertura"
            />
          </div>
          <div class="mb-4 col-md-6">
            <label class="fw-bold text-gray-600" label-for="descrizione"
              >Descrizione copertura{{
                campo.id_tipo_campo_omologazione == 2 ? "*" : ""
              }}</label
            >
            <Field
              type="text"
              class="form-control"
              :name="`campi[${index}].descrizione_copertura`"
              v-model="campo.descrizione_copertura"
              :disabled="campo.id_tipo_campo_omologazione != 2"
            />
            <ErrorMessage
              class="help-message"
              style="color: red"
              :name="`campi[${index}].descrizione_copertura`"
            />
          </div>
        </div>
      </div>
      <div class="row align-items-baseline">
        <div class="mb-4 col-md-3">
          <label class="fw-bold text-gray-600" label-for="descrizione"
            >Impianto wifi raggiungibile dai campi*</label
          >
          <SelectInput
            :options="optionsTrueFalse"
            :name="`campi[${index}].presenza_wifi`"
            placeholder="-"
            :value="campo.presenza_wifi"
            @changeSelect="setWiFi(index, $event)"
          />
        </div>
        <div class="mb-4 col-md-3">
          <label class="fw-bold text-gray-600" label-for="descrizione"
            >Impianto illuminazione emergenza*</label
          >
          <SelectInput
            :options="optionsTrueFalse"
            :name="`campi[${index}].presenza_illuminazione_emergenza`"
            placeholder="-"
            :value="campo.presenza_illuminazione_emergenza"
            @changeSelect="setIlluminazioneEmerg(index, $event)"
          />
        </div>
        <div class="mb-4 col-md-3">
          <label class="fw-bold text-gray-600" label-for="descrizione"
            >Impianto antincendio*</label
          >
          <SelectInput
            :options="optionsTrueFalse"
            :name="`campi[${index}].presenza_impianto_antincendio`"
            placeholder="-"
            :value="campo.presenza_impianto_antincendio"
            @changeSelect="setAntincendio(index, $event)"
          />
        </div>
        <div class="mb-4 col-md-3">
          <label class="fw-bold text-gray-600" label-for="descrizione"
            >Impianto di diffusione sonora*</label
          >
          <SelectInput
            :options="optionsTrueFalse"
            :name="`campi[${index}].presenza_impianto_sonoro`"
            placeholder="-"
            :value="campo.presenza_impianto_sonoro"
            @changeSelect="setImpSonoro(index, $event)"
          />
        </div>
      </div>
      <div class="row align-items-baseline">
        <div class="mb-4 col-md-6">
          <label class="fw-bold text-gray-600" label-for="descrizione"
            >Pavimentazione campo*</label
          >
          <SelectInput
            :options="impianti_campi_tipo_pavimentazione"
            :name="`campi[${index}].id_tipo_pavimentazione`"
            placeholder="-"
            :value="campo.id_tipo_pavimentazione"
            @changeSelect="setTipoPavimentazione(index, $event)"
          />
        </div>
        <div class="mb-4 col-md-3">
          <label class="fw-bold text-gray-600" label-for="descrizione"
            >Campo accessibile ai giocatori wheelchair*</label
          >

          <SelectInput
            :options="optionsTrueFalse"
            :name="`campi[${index}].accessibile_wheelchair`"
            placeholder="-"
            :value="campo.accessibile_wheelchair"
            @changeSelect="setAccessibileWeelchair(index, $event)"
          />
        </div>
        <div class="mb-4 col-md-3">
          <label class="fw-bold text-gray-600" label-for="descrizione"
            >Sedia dell'arbitro*</label
          >

          <SelectInput
            :options="optionsTrueFalse"
            :name="`campi[${index}].sedia_arbitro`"
            placeholder="-"
            :value="campo.sedia_arbitro"
            @changeSelect="setSediaArbitro(index, $event)"
            :disabled="campo.id_disciplina == 172"
          />
        </div>
      </div>
      <div class="row align-items-baseline">
        <div class="mb-4 col-md-3">
          <label class="fw-bold text-gray-600" label-for="descrizione"
            >Impianto di illuminazione artificiale fissa del campo di
            gioco*</label
          >
          <SelectInput
            :options="optionsTrueFalse"
            :name="`campi[${index}].illuminazione_artificiale_fissa`"
            placeholder="-"
            :value="campo.illuminazione_artificiale_fissa"
            @changeSelect="
              changeIlluminazioneArtificialeFissaCampo(index, $event)
            "
          />
        </div>
        <div class="mb-4 col-md-2">
          <label class="fw-bold text-gray-600" label-for="disciplina"
            >Apparecchi illuminanti n.*</label
          ><Field
            type="number"
            min="0"
            class="form-control"
            :name="`campi[${index}].numero_apparecchi_illuminanti_fissi`"
            v-model="campo.numero_apparecchi_illuminanti_fissi"
            :disabled="campo.illuminazione_artificiale_fissa != 1"
          />
          <ErrorMessage
            class="help-message"
            style="color: red"
            :name="`campi[${index}].numero_apparecchi_illuminanti_fissi`"
          />
        </div>
        <div class="mb-4 col-md-3">
          <label class="fw-bold text-gray-600" label-for="disciplina"
            >Tipologia*</label
          ><Field
            type="text"
            class="form-control"
            :name="`campi[${index}].tipo_apparecchi_illuminanti_fissi`"
            v-model="campo.tipo_apparecchi_illuminanti_fissi"
            :disabled="campo.illuminazione_artificiale_fissa != 1"
          />
          <ErrorMessage
            class="help-message"
            style="color: red"
            :name="`campi[${index}].tipo_apparecchi_illuminanti_fissi`"
          />
        </div>
        <div class="mb-4 col-md-2">
          <label class="fw-bold text-gray-600" label-for="disciplina"
            >Lux*</label
          ><Field
            type="number"
            min="0"
            class="form-control"
            :name="`campi[${index}].lux_apparecchi_illuminanti_fissi`"
            v-model="campo.lux_apparecchi_illuminanti_fissi"
            :disabled="campo.illuminazione_artificiale_fissa != 1"
          />
          <ErrorMessage
            class="help-message"
            style="color: red"
            :name="`campi[${index}].lux_apparecchi_illuminanti_fissi`"
          />
        </div>
        <div class="mb-4 col-md-2">
          <label class="fw-bold text-gray-600" label-for="disciplina"
            >H dal suolo (m)*</label
          ><Field
            type="number"
            min="0"
            class="form-control"
            :name="`campi[${index}].altezza_apparecchi_illuminanti_fissi`"
            v-model="campo.altezza_apparecchi_illuminanti_fissi"
            :disabled="campo.illuminazione_artificiale_fissa != 1"
          />
          <ErrorMessage
            class="help-message"
            style="color: red"
            :name="`campi[${index}].altezza_apparecchi_illuminanti_fissi`"
          />
        </div>
      </div>
      <div class="row align-items-baseline">
        <div class="mb-4 col-md-3">
          <label class="fw-bold text-gray-600" label-for="descrizione"
            >Impianto di illuminazione artificiale aggiuntiva del campo di
            gioco*</label
          >

          <SelectInput
            :options="optionsTrueFalse"
            :name="`campi[${index}].illuminazione_artificiale_aggiuntiva`"
            placeholder="-"
            :value="campo.illuminazione_artificiale_aggiuntiva"
            @changeSelect="
              changeIlluminazioneArtificialeAggiuntivaCampo(index, $event)
            "
          />
        </div>
        <div class="mb-4 col-md-2">
          <label class="fw-bold text-gray-600" label-for="disciplina"
            >Apparecchi illuminanti n.*</label
          ><Field
            type="number"
            min="0"
            class="form-control"
            :name="`campi[${index}].numero_apparecchi_illuminanti_aggiuntivi`"
            v-model="campo.numero_apparecchi_illuminanti_aggiuntivi"
            :disabled="campo.illuminazione_artificiale_aggiuntiva != 1"
          />
          <ErrorMessage
            class="help-message"
            style="color: red"
            :name="`campi[${index}].numero_apparecchi_illuminanti_aggiuntivi`"
          />
        </div>
        <div class="mb-4 col-md-3">
          <label class="fw-bold text-gray-600" label-for="disciplina"
            >Tipologia*</label
          ><Field
            type="text"
            class="form-control"
            :name="`campi[${index}].tipo_apparecchi_illuminanti_aggiuntivi`"
            v-model="campo.tipo_apparecchi_illuminanti_aggiuntivi"
            :disabled="campo.illuminazione_artificiale_aggiuntiva != 1"
          />
          <ErrorMessage
            class="help-message"
            style="color: red"
            :name="`campi[${index}].tipo_apparecchi_illuminanti_aggiuntivi`"
          />
        </div>
        <div class="mb-4 col-md-2">
          <label class="fw-bold text-gray-600" label-for="disciplina"
            >Lux*</label
          ><Field
            type="number"
            min="0"
            class="form-control"
            :name="`campi[${index}].lux_apparecchi_illuminanti_aggiuntivi`"
            v-model="campo.lux_apparecchi_illuminanti_aggiuntivi"
            :disabled="campo.illuminazione_artificiale_aggiuntiva != 1"
          />
          <ErrorMessage
            class="help-message"
            style="color: red"
            :name="`campi[${index}].lux_apparecchi_illuminanti_aggiuntivi`"
          />
        </div>
        <div class="mb-4 col-md-2">
          <label class="fw-bold text-gray-600" label-for="disciplina"
            >H dal suolo (m)*</label
          ><Field
            type="number"
            min="0"
            class="form-control"
            :name="`campi[${index}].altezza_apparecchi_illuminanti_aggiuntivi`"
            v-model="campo.altezza_apparecchi_illuminanti_aggiuntivi"
            :disabled="campo.illuminazione_artificiale_aggiuntiva != 1"
          />
          <ErrorMessage
            class="help-message"
            style="color: red"
            :name="`campi[${index}].altezza_apparecchi_illuminanti_aggiuntivi`"
          />
        </div>
      </div>
      <hr />
      <p class="fw-bold text-gray-700 mb-0 pt-4 pb-1">
        Installazioni per il pubblico:
      </p>
      <div class="row align-items-baseline mb-5">
        <div class="col-md-3">
          <label class="fw-bold text-gray-600" label-for="descrizione"
            >Tribuna fissa*</label
          >
          <SelectInput
            :options="optionsTrueFalse"
            :name="`campi[${index}].tribuna_fissa`"
            placeholder="-"
            :value="campo.tribuna_fissa"
            @changeSelect="changeTribunaFissaCampo(index, $event)"
          />
        </div>
        <div class="col-md-3">
          <label class="fw-bold text-gray-600" label-for="disciplina"
            >Numero tribune fisse*</label
          ><Field
            type="number"
            min="0"
            class="form-control"
            :name="`campi[${index}].numero_tribune_fisse`"
            v-model="campo.numero_tribune_fisse"
            :disabled="campo.tribuna_fissa != 1"
          />
          <ErrorMessage
            class="help-message"
            style="color: red"
            :name="`campi[${index}].numero_tribune_fisse`"
          />
        </div>
        <div class="col-md-3">
          <label class="fw-bold text-gray-600" label-for="disciplina"
            >Capienza posti a sedere tribune fisse* </label
          ><Field
            type="number"
            min="0"
            class="form-control"
            :name="`campi[${index}].capienza_tribuna_fissa`"
            v-model="campo.capienza_tribuna_fissa"
            :disabled="campo.tribuna_fissa != 1"
          />
          <ErrorMessage
            class="help-message"
            style="color: red"
            :name="`campi[${index}].capienza_tribuna_fissa`"
          />
        </div>
        <div class="col-md-3">
          <label class="fw-bold text-gray-600" label-for="disciplina"
            >Copertura*</label
          >

          <SelectInput
            :options="optionsTrueFalse"
            :name="`campi[${index}].copertura_tribuna_fissa`"
            placeholder="-"
            :value="campo.copertura_tribuna_fissa"
            @changeSelect="setCoperturaTribunaFissa(index, $event)"
            :disabled="campo.tribuna_fissa != 1"
          />
        </div>
      </div>
      <div class="row align-items-baseline mb-5">
        <div class="mb-4 col-md-4">
          <label class="fw-bold text-gray-600" label-for="descrizione"
            >Tribuna mobile*</label
          >

          <SelectInput
            :options="optionsTrueFalse"
            :name="`campi[${index}].tribuna_mobile`"
            placeholder="-"
            :value="campo.tribuna_mobile"
            @changeSelect="changeTribunaMobileCampo(index, $event)"
          />
        </div>
        <div class="mb-4 col-md-4">
          <label class="fw-bold text-gray-600" label-for="disciplina"
            >Numero tribune mobili*</label
          ><Field
            type="number"
            min="0"
            class="form-control"
            :name="`campi[${index}].numero_tribune_mobili`"
            v-model="campo.numero_tribune_mobili"
            :disabled="campo.tribuna_mobile != 1"
          />
          <ErrorMessage
            class="help-message"
            style="color: red"
            :name="`campi[${index}].numero_tribune_mobili`"
          />
        </div>
        <div class="mb-4 col-md-4">
          <label class="fw-bold text-gray-600" label-for="disciplina"
            >Capienza posti a sedere tribune mobile*</label
          ><Field
            type="number"
            min="0"
            class="form-control"
            :name="`campi[${index}].capienza_tribuna_mobile`"
            v-model="campo.capienza_tribuna_mobile"
            :disabled="campo.tribuna_mobile != 1"
          />
          <ErrorMessage
            class="help-message"
            style="color: red"
            :name="`campi[${index}].capienza_tribuna_mobile`"
          />
        </div>
      </div>
      <div class="row align-items-baseline">
        <div
          :class="
            campo.id_disciplina == 4332 ? 'mb-4 col-md-2' : 'mb-4 col-md-3'
          "
        >
          <label class="fw-bold text-gray-600" label-for="descrizione"
            >N. servizi igienici pubblico*</label
          >
          <Field
            type="number"
            min="0"
            class="form-control"
            :name="`campi[${index}].numero_servizi_pubblico`"
            v-model="campo.numero_servizi_pubblico"
          />
          <ErrorMessage
            class="help-message"
            style="color: red"
            :name="`campi[${index}].numero_servizi_pubblico`"
          />
        </div>
        <div
          :class="
            campo.id_disciplina == 4332 ? 'mb-4 col-md-2' : 'mb-4 col-md-3'
          "
        >
          <label class="fw-bold text-gray-600" label-for="disciplina"
            >N. lavabi*</label
          ><Field
            type="number"
            min="0"
            class="form-control"
            :name="`campi[${index}].numero_lavabi_servizi_pubblico`"
            v-model="campo.numero_lavabi_servizi_pubblico"
          />
          <ErrorMessage
            class="help-message"
            style="color: red"
            :name="`campi[${index}].numero_lavabi_servizi_pubblico`"
          />
        </div>
        <div
          :class="
            campo.id_disciplina == 4332 ? 'mb-4 col-md-2' : 'mb-4 col-md-3'
          "
        >
          <label class="fw-bold text-gray-600" label-for="disciplina"
            >N. WC*</label
          >
          <Field
            type="number"
            min="0"
            class="form-control"
            :name="`campi[${index}].numero_wc_servizi_pubblico`"
            v-model="campo.numero_wc_servizi_pubblico"
          />
          <ErrorMessage
            class="help-message"
            style="color: red"
            :name="`campi[${index}].numero_wc_servizi_pubblico`"
          />
        </div>

        <div
          :class="
            campo.id_disciplina == 4332 ? 'mb-4 col-md-3' : 'mb-4 col-md-3'
          "
        >
          <label class="fw-bold text-gray-600" label-for="descrizione"
            >Abbattimento barriere architettoniche per il pubblico*</label
          >
          <SelectInput
            :options="optionsTrueFalse"
            :name="`campi[${index}].accessibilita_pubblico`"
            placeholder="-"
            :value="campo.accessibilita_pubblico"
            @changeSelect="setAccessibilitaPubblico(index, $event)"
          />
        </div>
        <template v-if="campo.id_disciplina === 4332">
          <div class="mb-4 col-md-3">
            <label class="fw-bold text-gray-600" label-for="descrizione"
              >Campo singolare/doppio*
            </label>
            <SelectInput
              :options="omologazioni_tipologia_campi_singoli"
              :name="`campi[${index}].id_tipologia_campo_singolo`"
              placeholder="-"
              :value="campo.id_tipologia_campo_singolo"
              @changeSelect="changeCampoSingoloDoppio(index, $event)"
              :disabled="campo.id_disciplina != 4332"
            />
          </div>
        </template>
      </div>
      <div class="row align-items-baseline" v-if="campo.id_disciplina === 4332">
        <div v-if="campo.id_tipologia_campo_singolo == 1" class="mb-4 col-md-6">
          <label class="fw-bold text-gray-600" label-for="disciplina"
            >Distanza da riga singolare dal centro del palo da singolare
            1:*</label
          ><Field
            type="number"
            min="0"
            class="form-control"
            :name="`campi[${index}].distanza_riga_singolare_1`"
            v-model="campo.distanza_riga_singolare_1"
          />
          <ErrorMessage
            class="help-message"
            style="color: red"
            :name="`campi[${index}].distanza_riga_singolare_1`"
          />
        </div>
        <div class="mb-4 col-md-6" v-if="campo.id_tipologia_campo_singolo == 1">
          <label class="fw-bold text-gray-600" label-for="disciplina"
            >Distanza da riga singolare dal centro del palo da singolare
            2:*</label
          ><Field
            type="number"
            min="0"
            class="form-control"
            :name="`campi[${index}].distanza_riga_singolare_2`"
            v-model="campo.distanza_riga_singolare_2"
          />
          <ErrorMessage
            class="help-message"
            style="color: red"
            :name="`campi[${index}].distanza_riga_singolare_2`"
          />
        </div>
        <div class="mb-4 col-md-3" v-if="campo.id_tipologia_campo_singolo == 2">
          <label class="fw-bold text-gray-600" label-for="disciplina"
            >Distanza da riga doppio dal centro del palo da doppio 1*:</label
          ><Field
            type="number"
            min="0"
            class="form-control"
            :name="`campi[${index}].distanza_riga_doppio_1`"
            v-model="campo.distanza_riga_doppio_1"
          />
          <ErrorMessage
            class="help-message"
            style="color: red"
            :name="`campi[${index}].distanza_riga_doppio_1`"
          />
        </div>
        <div class="mb-4 col-md-3" v-if="campo.id_tipologia_campo_singolo == 2">
          <label class="fw-bold text-gray-600" label-for="disciplina"
            >Distanza da riga doppio dal centro del palo da doppio 2*:</label
          ><Field
            type="number"
            min="0"
            class="form-control"
            :name="`campi[${index}].distanza_riga_doppio_2`"
            v-model="campo.distanza_riga_doppio_2"
          />
          <ErrorMessage
            class="help-message"
            style="color: red"
            :name="`campi[${index}].distanza_riga_doppio_2`"
          />
        </div>
        <div class="mb-4 col-md-3" v-if="campo.id_tipologia_campo_singolo == 2">
          <label class="fw-bold text-gray-600" label-for="disciplina"
            >Larghezza corridoio 1*:</label
          ><Field
            type="number"
            min="0"
            class="form-control"
            :name="`campi[${index}].larghezza_corridoio_1`"
            v-model="campo.larghezza_corridoio_1"
          />
          <ErrorMessage
            class="help-message"
            style="color: red"
            :name="`campi[${index}].larghezza_corridoio_1`"
          />
        </div>
        <div class="mb-4 col-md-3" v-if="campo.id_tipologia_campo_singolo == 2">
          <label class="fw-bold text-gray-600" label-for="disciplina"
            >Larghezza corridoio 2*:</label
          ><Field
            type="number"
            min="0"
            class="form-control"
            :name="`campi[${index}].larghezza_corridoio_2`"
            v-model="campo.larghezza_corridoio_2"
          />
          <ErrorMessage
            class="help-message"
            style="color: red"
            :name="`campi[${index}].larghezza_corridoio_2`"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent, computed, ref, getCurrentInstance } from "vue";
import { ErrorMessage, Field } from "vee-validate";
import Datepicker from "vue3-date-time-picker";
import Popper from "vue3-popper";
import { useStore } from "vuex";
import SelectInput from "../utility/inputs/SelectInput.vue";
import SelectInput2 from "../utility/inputs/SelectInput_v03_NoSimilar.vue";
export default defineComponent({
  name: "add-omologazione",
  props: {
    campoProps: {
      type: Object,
    },
    index: {
      type: Number,
    },
    statoprops: {
      type: Number,
    },
  },
  components: {
    Popper,
    SelectInput,
    SelectInput2,
    ErrorMessage,
    Field,
    Datepicker,
  },
  setup(props) {
    const campo = ref({ ...props.campoProps });
    const store = useStore();
    const omologazioni_tipologia_vetro_campi = computed(() =>
      store.getters.getStateFromName("omologazioni_tipologia_vetro_campi")
    );
    const omologazioni_tipo_impianti_campo = computed(() =>
      store.getters.getStateFromName("omologazioni_tipo_impianti_campo")
    );
    const omologazioni_tipo_copertura_campi = computed(() =>
      store.getters.getStateFromName("omologazioni_tipo_copertura_campi")
    );
    const omologazioni_campi_coperti = computed(() =>
      store.getters.getStateFromName("omologazioni_campi_coperti")
    );
    const omologazioni_tipologia_copertura_impianti = computed(() =>
      store.getters.getStateFromName(
        "omologazioni_tipologia_copertura_impianti"
      )
    );
    const impianti_campi_tipo_pavimentazione = computed(() =>
      store.getters.getStateFromName("impianti_campi_tipo_pavimentazione")
    );
    const omologazioni_tipologia_campi_singoli = computed(() =>
      store.getters.getStateFromName("omologazioni_tipologia_campi_singoli")
    );

    const keys = ref("otvc|otic|otcc|occ|otci|otpc|otcs");
    const instance = getCurrentInstance();
    const globalApi =
      instance.appContext.config.globalProperties.$apiListGlobal;
    if (
      !omologazioni_tipologia_vetro_campi.value ||
      !omologazioni_tipo_impianti_campo.value ||
      !omologazioni_tipo_copertura_campi.value ||
      !omologazioni_campi_coperti.value ||
      !omologazioni_tipologia_copertura_impianti.value ||
      !impianti_campi_tipo_pavimentazione.value ||
      !omologazioni_tipologia_campi_singoli.value
    ) {
      store.dispatch("setStoreData", {
        keys: { keys: keys.value },
        apiLink: globalApi.COMBOLIST_GET,
      });
    }

    const changeTipologiaCoperturaCampo = (index, e) => {
      campo.value.id_tipo_campo_omologazione = parseInt(e);
      campo.value.presenza_climatizzazione = null;
      campo.value.tipo_copertura = null;
      campo.value.altezza_laterale_1 = null;
      campo.value.altezza_laterale_2 = null;
      campo.value.altezza_fondo_1 = null;
      campo.value.altezza_fondo_2 = null;
      campo.value.altezza_massima = null;
      changeTipologiaCoperturaCampoCoperto(index, null);
      campo.value.altezza_centro_rete = null;
      campo.value.altezza_linee_laterali = null;
      campo.value.altezza_linea_fondo = null;
      campo.value.altezza_angoli = null;
      changePeriodoCoperturaCampo(index, null);
    };
    const changeTipologiaCoperturaCampoCoperto = (index, e) => {
      campo.value.tipologie_campi_coperti = parseInt(e);
      campo.value.aperture_scorrevoli_laterali = null;
    };
    const setClimatizzazione = (index, e) => {
      campo.value.presenza_climatizzazione = e;
    };
    const changePeriodoCoperturaCampo = (index, e) => {
      campo.value.tipologia_copertura = parseInt(e);
      campo.value.data_inizio_copertura = null;
      campo.value.data_fine_copertura = null;
    };
    const setIlluminazioneEmerg = (index, e) => {
      campo.value.presenza_illuminazione_emergenza = e;
    };
    const setWiFi = (index, e) => {
      campo.value.presenza_wifi = e;
    };
    const setAntincendio = (index, e) => {
      campo.value.presenza_impianto_antincendio = e;
    };
    const setImpSonoro = (index, e) => {
      campo.value.presenza_impianto_sonoro = e;
    };
    const setTipoPavimentazione = (index, e) => {
      campo.value.id_tipo_pavimentazione = parseInt(e);
    };
    const setAccessibileWeelchair = (index, e) => {
      campo.value.accessibile_wheelchair = e;
    };
    const setSediaArbitro = (index, e) => {
      campo.value.sedia_arbitro = e;
    };
    const changeIlluminazioneArtificialeFissaCampo = (index, e) => {
      campo.value.illuminazione_artificiale_fissa = e;
      campo.value.numero_apparecchi_illuminanti_fissi = null;
      campo.value.tipo_apparecchi_illuminanti_fissi = null;
      campo.value.lux_apparecchi_illuminanti_fissi = null;
      campo.value.altezza_apparecchi_illuminanti_fissi = null;
    };
    const changeIlluminazioneArtificialeAggiuntivaCampo = (index, e) => {
      campo.value.illuminazione_artificiale_aggiuntiva = e;
      campo.value.numero_apparecchi_illuminanti_aggiuntivi = null;
      campo.value.tipo_apparecchi_illuminanti_aggiuntivi = null;
      campo.value.lux_apparecchi_illuminanti_aggiuntivi = null;
      campo.value.altezza_apparecchi_illuminanti_aggiuntivi = null;
    };
    const changeTribunaFissaCampo = (index, e) => {
      campo.value.tribuna_fissa = e;
      campo.value.numero_tribune_fisse = null;
      campo.value.capienza_tribuna_fissa = null;
      campo.value.copertura_tribuna_fissa = null;
    };
    const setCoperturaTribunaFissa = (index, e) => {
      campo.value.copertura_tribuna_fissa = e;
    };
    const changeTribunaMobileCampo = (index, e) => {
      campo.value.tribuna_mobile = e;
      campo.value.numero_tribune_mobili = null;
      campo.value.capienza_tribuna_mobile = null;
    };
    const setAccessibilitaPubblico = (index, e) => {
      campo.value.accessibilita_pubblico = e;
    };
    const changeCampoSingoloDoppio = (index, e) => {
      campo.value.id_tipologia_campo_singolo = parseInt(e);
      campo.value.distanza_riga_singolare_1 = null;
      campo.value.distanza_riga_singolare_2 = null;
      campo.value.distanza_riga_doppio_1 = null;
      campo.value.distanza_riga_doppio_2 = null;
    };
    return {
      changeCampoSingoloDoppio,
      setAccessibilitaPubblico,
      changeTribunaMobileCampo,
      setCoperturaTribunaFissa,
      changeTribunaFissaCampo,
      changeIlluminazioneArtificialeAggiuntivaCampo,
      changeIlluminazioneArtificialeFissaCampo,
      setSediaArbitro,
      setAccessibileWeelchair,
      setTipoPavimentazione,
      setImpSonoro,
      setAntincendio,
      setWiFi,
      setIlluminazioneEmerg,
      changePeriodoCoperturaCampo,
      setClimatizzazione,
      changeTipologiaCoperturaCampoCoperto,
      changeTipologiaCoperturaCampo,
      campo,
      optionsTrueFalse: [
        { id: false, label: "No" },
        { id: true, label: "Si" },
      ],
      omologazioni_tipologia_vetro_campi,
      omologazioni_tipo_impianti_campo,
      omologazioni_tipo_copertura_campi,
      omologazioni_campi_coperti,
      omologazioni_tipologia_copertura_impianti,
      impianti_campi_tipo_pavimentazione,
      omologazioni_tipologia_campi_singoli,
    };
  },
});
</script>
